import { useRef } from "react";
import Content from "../content/Content";
import Headband from "../headband/Headband";
import Title from "../title/Title";
import "./Store.css";

const Store = () => {
    const ref = useRef<HTMLIFrameElement>(null);

    return (
        <Content>
            <Headband text="la boutique" />
            <Title title="Le textile des Pumas" />
            <div>
                <iframe
                    src="https://www.helloasso.com/associations/pumas-de-pau/boutiques/soldes-2023-boutique-pumas-de-pau/widget"
                    width="100%"
                    height="1000"
                    style={{
                        border: 'none',
                    }}
                />
            </div>
        </Content>
    )
}

export default Store;
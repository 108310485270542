import { Link } from "react-router-dom";
import logo from "../../static/images/logo_menu.png";
import "./Menu.css";

const Menu = () => {
    return (<>
        <div id="header">
            <div id="fullMenu">
                <div id="logo">
                    <Link to="/">
                        <img src={logo} loading="lazy" width="" height="117" alt="" />
                    </Link>
                </div>
                <div id="menu">
                    <div id="menus">
                        <Link to="/partenaires" id="mainMenu" >Partenaires</Link>
                    </div>

                    <div id="menus">
                        <Link to="/boutique" id="mainMenu">Boutique</Link>
                    </div>
                    <div id="menus">
                        <Link to="/calendrier" id="mainMenu">Calendriers</Link>
                    </div>
                    <div id="menus">
                        <div id="mainMenu">
                            Nous rejoindre
                        </div>
                        <Link to="/entrainements">
                            <div id="subMenus">
                                Entrainements
                            </div>
                        </Link>
                        <Link to="/inscriptions">
                            <div id="subMenus">
                                Inscription et tarifs
                            </div>
                        </Link>
                    </div>
                    <div id="menus">
                        <div id="mainMenu">
                            Le club
                        </div>
                        <Link to="/historique">
                            <div id="subMenus">
                                Historique
                            </div>
                        </Link>
                        <Link to="/contacts">
                            <div id="subMenus">
                                L'encadrement
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>);

}

export default Menu;
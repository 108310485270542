import { PortableText } from "@portabletext/react";
import { useEffect, useState } from "react";
import sanityService from "../../services/sanity-service";
import Content from "../content/Content";
import Headband from "../headband/Headband";
import "./History.css";

interface Year {
    date: number,
    text: any
}

const History = () => {

    const [years, setYears] = useState<Year[]>([])
    let index = 0;
    useEffect(() => {
        sanityService.doQuery("*[_type == 'history'] | order(year desc) {text,year}").then(response => setYears(response.map((element: any): Year => {
            return {
                date: element.year,
                text: element.text
            }
        })))
    }, [])

    return (
        <Content>
            <Headband text="histoire du club" />
            <div className="timeline">
                {
                    years.map(year =>
                        <div className={index++ % 2 === 0 ? "container timeline_right" : "container timeline_left"}>
                            <div key={year.date} className="content">
                                <h2>{year.date}</h2>
                                <PortableText value={year.text} />
                            </div>
                        </div>)
                }
            </div>
        </Content>
    )
}

export default History;
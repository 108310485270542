import u15 from "../../static/images/banner/15u.webp";
import u9 from "../../static/images/banner/9u.webp";
import r1 from "../../static/images/banner/R1.webp";
import r3 from "../../static/images/banner/R3.webp";
import soft from "../../static/images/banner/Soft.webp";
import logo from "../../static/images/banner/welcome-logo.png";
import Headband from "../headband/Headband";
import "./Banner.css";

function getBannerImage() {
    const banners = [r1, r3, u9, u15, soft];

    var date = new Date();
    var bannerIndex = date.getMinutes() % banners.length;
    var banner = banners[bannerIndex]
    return banner;
}

const Banner = () => {
    const image = getBannerImage();
    console.log(image);
    return (<>
        <div id="welcome">
            <div id="welcome-image" style={{
                backgroundImage: `url(${image})`,
            }}>
                <img id="team-image" src="" alt="" />
                <div id="welcome-logo">
                    <img src={logo} height="350px" alt="" />
                </div>
                <div id="welcome-text">
                    <Headband text={"BIENVENUE CHEZ LES PUMAS"} />
                </div>
            </div>
        </div >
        <script>

        </script>
    </>);
}

export default Banner;
import { Scheduler } from "@aldabil/react-scheduler";
import { ProcessedEvent } from "@aldabil/react-scheduler/types";
import { fr } from 'date-fns/esm/locale';
import sanityService from "../../services/sanity-service";
import Content from "../content/Content";
import Headband from "../headband/Headband";
import "./entrainement.css";

const Entrainement = () => {

    const getDayOfCurrentWeek = (dayOfTheWeek: number, hour: number, minutes: number): Date => {
        const day = new Date();
        day.setHours(hour, minutes, 0);
        if (day.getDay() === dayOfTheWeek) return day
        if (day.getDay() === 0) day.setDate(day.getDate() + ((dayOfTheWeek - day.getDay()) % 7) - 7);
        else day.setDate(day.getDate() + (dayOfTheWeek - day.getDay()) % 7);
        return day;
    }

    const getEvents = async (): Promise<ProcessedEvent[]> => {
        return sanityService.doQuery('*[_type == \'practice\'] { title, dayOfTheWeek, \'start\': duration.debut, \'end\' : duration.fin }')
            .then(response => {
                var id: number = 1;
                return response.map((element: any): ProcessedEvent => {
                    const start = element.start.split(':');
                    const end = element.end.split(':');
                    return {
                        event_id: id++,
                        title: element.title,
                        start: getDayOfCurrentWeek(element.dayOfTheWeek, start[0], start[1]),
                        end: getDayOfCurrentWeek(element.dayOfTheWeek, end[0], end[1]),
                        deletable: false
                    }
                })
            });

    }

    return (
        <Content>
            <Headband text="les entrainements" />
            <div style={{ height: "1000px" }}>
                <Scheduler
                    getRemoteEvents={getEvents}
                    view="week"
                    editable={false}
                    week={{
                        weekDays: [0, 1, 2, 3, 4, 5, 6],
                        weekStartOn: 1,
                        startHour: 8,
                        endHour: 23,
                        step: 60,
                    }}
                    navigation={false}
                    disableViewNavigator={true}
                    locale={fr}
                    hourFormat="24"
                />
            </div>
        </Content>
    );
}

export default Entrainement;
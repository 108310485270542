import Content from "../content/Content";

const Error = () => {
    return (
        <Content>
            Vous semblez perdu...
        </Content>
    )
}

export default Error;
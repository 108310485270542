import { useEffect, useState } from 'react';
import { INewsItem } from '../../model/NewsItem';
import sanityService from "../../services/sanity-service";
import "./News.css";
import NewsButton from './NewsButton';
import NewsItem from './NewsItem';

const News = () => {

    const [news, setNews] = useState<INewsItem[][]>([]);
    const [displayedNews, setDisplayedNews] = useState<INewsItem>()
    const [selectableNews, setSelectableNews] = useState<INewsItem[]>([])

    const updateNews = (newsId: string): void => {
        setDisplayedNews(selectableNews.find(n => n.id === newsId));
    }

    const updateSelectableNews = (selectableNewsIndex: number): void => {
        if (selectableNewsIndex - 1 < news.length && selectableNewsIndex - 1 >= 0) {
            setSelectableNews(news[selectableNewsIndex - 1]);
            setDisplayedNews(news[selectableNewsIndex - 1][0]);
        }
    }

    useEffect(() => {
        sanityService.doQuery('*[_type == "news"]')
            .then(result => {
                let news = result.map((element: any): INewsItem => {
                    return {
                        id: element._id,
                        title: element.title,
                        subtitle: element.subtitle,
                        article: element.article,
                        imagesUrl: element.images ? element.images.map((image: any) => sanityService.urlFor(image).url()) : [],
                        creationDate: new Date(element._createdAt)
                    }
                })
                    .sort((a: INewsItem, b: INewsItem) => b.creationDate.getTime() - a.creationDate.getTime()).reduce((resultArray: INewsItem[][], item: INewsItem, index: number) => {
                        const chunkIndex = Math.floor(index / 10)
                        if (!resultArray[chunkIndex]) {
                            resultArray[chunkIndex] = [] // start a new chunk
                        }
                        resultArray[chunkIndex].push(item)
                        return resultArray
                    }, []);
                return news;
            }).then(news => {
                setNews(news);
                if (news.length > 0) {
                    setSelectableNews(news[0]);
                    setDisplayedNews(news[0][0]);
                }
            });
    }, [])

    return (
        <div id="newsContainer">
            <div className="newsTab">
                {selectableNews.map(newsItem => <NewsButton key={newsItem.id} newsId={newsItem.id} title={newsItem.title} subtitle={newsItem.subtitle} updateNews={updateNews} isSelected={displayedNews === undefined ? false : newsItem.id === displayedNews.id} />)}
            </div>
            <NewsItem news={displayedNews} updateSelectableNew={updateSelectableNews} totalPagesNumber={news.length === 0 ? 0 : news.length} />
        </div>
    );
}

export default News;
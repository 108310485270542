import React from "react";
import "./Title.css"

interface Props {
    title: string,
}

const Title = ({ title }: Props) => {
    var splitted = title.split(" ");
    var start = splitted.shift();
    var middle = splitted.shift();
    var end = splitted.join(" ")

    return (<>
        <div id="bandeau">
            {start}
            <span id="jaune"> {middle}</span> {end}
        </div>
    </>);

}

export default Title;
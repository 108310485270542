import "./NewsButton.css";

interface Props {
    newsId: string,
    title: string,
    subtitle: string,
    updateNews: (newsId: string) => void
    isSelected: boolean
}

const NewsButton = ({ newsId, title, subtitle, updateNews, isSelected }: Props) => {
    return (
        <button className={isSelected ? "tablinks activated" : "tablinks"} onClick={() => updateNews(newsId)}>
            {title}
            <div className="button_subtitle">
                {subtitle}
            </div>
        </button>
    );
}

export default NewsButton;
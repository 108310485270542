import { ThemeProvider, createTheme } from '@mui/material';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Error from './components/404/Error';
import Calendar from './components/calendar/Calendar';
import Entrainement from './components/entrainements/entrainement';
import Footer from './components/footer/Footer';
import History from './components/history/History';
import Inscriptions from './components/inscriptions/Inscriptions';
import LandingZone from './components/landing-zone/LandingZone';
import Managers from './components/managers/Managers';
import Menu from './components/menu/Menu';
import Partners from './components/partners/Partners';
import Store from './components/store/Store';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#c5b358',
        // light: will be calculated from palette.primary.main,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: '#000000',

      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <div className="App">
          <header className="App-header">
          </header>
          <Menu />
          <Routes>
            <Route path='*' element={<Error />} />
            <Route path='/' element={<LandingZone />} />
            <Route path='/inscriptions' element={<Inscriptions />} />
            <Route path='/calendrier' element={<Calendar />} />
            <Route path='/entrainements' element={<Entrainement />} />
            <Route path='/contacts' element={<Managers />} />
            <Route path='/boutique' element={<Store />} />
            <Route path='/partenaires' element={<Partners />} />
            <Route path='/historique' element={<History />} />
          </Routes>
          <Footer />
        </div>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;

import { useEffect, useState } from 'react';
import sanityService from '../../services/sanity-service';
import Content from '../content/Content';
import Title from '../title/Title';
import './Calendar.css';

interface TeamCalendar {
    title: string,
    pdfURL: string,
}

interface MonthCalendar {
    imageURL: string,
    month: number,
}

const Calendar = () => {

    const [teamCalendars, setTeamCalendars] = useState<TeamCalendar[]>([]);
    const [monthCalendars, setMonthCalendars] = useState<MonthCalendar[]>([]);

    useEffect(() => {
        sanityService.doQuery("*[_type == 'teamCalendar'] | order(_createdAt asc) {'title' : title, 'pdfURL' : calendarPDF.asset ->url}")
            .then(res => setTeamCalendars(res.map((element: any): TeamCalendar => {
                return {
                    title: element.title,
                    pdfURL: element.pdfURL,
                }
            })))
        sanityService.doQuery("*[_type == 'clubCalendar'] {'imageURL': calendarImage.asset->url, 'month': month}")
            .then(res => setMonthCalendars(res.map((element: any): MonthCalendar => {
                return {
                    imageURL: element.imageURL,
                    month: element.month,
                }
            })))
    }, []);

    const TeamCalendarButton = (calendar: TeamCalendar) => {
        return (
            <div className="fullCalendarUnit">
                <form method="get" action={calendar.pdfURL}>
                    <button className="button" type="submit">{calendar.title}</button>
                </form>
            </div>
        );
    }

    const MonthlyCalendar = () => {
        const currentMonth: number = new Date().getMonth() + 1;
        const currentMonthCalendar = monthCalendars.find(calendar => calendar.month == currentMonth);
        const nextMonthCalendar = monthCalendars.find(calendar => calendar.month == currentMonth + 1)
        return (<>
            <img className="calendarTop" src={currentMonthCalendar?.imageURL} loading="lazy" width="1000" alt="" />
            <img className="calendarBottom" src={nextMonthCalendar?.imageURL} loading="lazy" width="1000" alt="" />
        </>)
    }


    return (
        <Content>
            <Title title='Les calendriers par équipe' />
            <div className="fullCalendarsDiv">
                {teamCalendars.map(calendar => <TeamCalendarButton key={calendar.title} title={calendar.title} pdfURL={calendar.pdfURL} />)}
            </div>
            <Title title='Le calendrier du club' />
            <MonthlyCalendar />
        </Content>
    )
}

export default Calendar;
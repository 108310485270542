import { Pagination } from "@mui/material";
import * as React from 'react';

import { PortableText } from "@portabletext/react";
import { Slide } from "react-slideshow-image";
import { INewsItem } from "../../model/NewsItem";
import "./NewsItem.css";

interface Props {
    news: INewsItem | undefined,
    totalPagesNumber: number,
    updateSelectableNew: (selectableNewsIndex: number) => void,
}

const NewsItem = ({ news, totalPagesNumber, updateSelectableNew }: Props) => {

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        updateSelectableNew(value);
    };

    function RenderSlideshow() {
        return (
            <Slide indicators={true} autoplay={false} cssClass="SlideShow">
                {news?.imagesUrl.map((slideImage, index) => (
                    <div className="parent" key={index}>
                        <img className="image" src={slideImage} />
                    </div>
                ))}
            </Slide>
        );
    }


    function RenderUniqueImage() {
        return (
            <div className="parent unique">
                <img className="image" src={news?.imagesUrl[0]} />
            </div>
        );
    }


    return (
        <div className="tabContent">
            <div>
                {news && news.imagesUrl.length > 1 ? <RenderSlideshow /> : <RenderUniqueImage />}
            </div>
            <div className="textNews">
                <PortableText
                    value={news?.article}
                />
            </div>
            <div className="pagination">
                <Pagination count={totalPagesNumber} shape="rounded" onChange={handleChange} showFirstButton showLastButton />
            </div>
        </div>
    );
}

export default NewsItem;
import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { DATASET, PROJECT_ID } from "../constants/sanity-constants";

const client = createClient({
    projectId: PROJECT_ID,
    dataset: DATASET,
    useCdn: true, // set to `false` to bypass the edge cache
    apiVersion: '2023-05-03', // use current date (YYYY-MM-DD) to target the latest API version
    // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
})

const builder = imageUrlBuilder(client)

export default {
    async doQuery(query: string): Promise<any> {
        return client.fetch(query);
    },

    urlFor(source: any): any {
        return builder.image(source).format('webp');
    }

}
import React from "react";
import logo from "../../static/images/footer/logofooter.png";
import lna from "../../static/images/footer/footer_labsc.png"
import ffbs from "../../static/images/footer/footer_ffbs.png"
import "./Footer.css"

interface Props {

}

const Footer = ({ }: Props) => {
    return (<>
        <footer>
            <div id="footer_logo_pumas">
                <img src={logo} loading="lazy" alt="" />
            </div>

            <div id="footer_logo_ligue">
                <a href="http://lna-baseball.com" target="_blank">
                    <img src={lna} loading="lazy" alt="" style={{ width: '50px', }} ></img>
                </a>
                <a href="https://ffbs.fr" target="_blank">
                    <img src={ffbs} loading="lazy" alt="" />
                </a>
            </div>
        </footer>
    </>);

}

export default Footer;
import { useEffect, useState } from "react";
import sanityService from "../../services/sanity-service";
import Content from "../content/Content";
import Headband from "../headband/Headband";
import Title from "../title/Title";
import "./Partners.css";

interface Partner {
  websiteUrl: string;
  logoUrl: string;
  mention: boolean;
}

const Partners = () => {
  const [partners, setPartners] = useState<Partner[]>([]);

  useEffect(() => {
    sanityService
      .doQuery("*[_type == 'partners'] {logo, url, mention}")
      .then((reponse) =>
        setPartners(
          reponse.map((element: any): Partner => {
            return {
              websiteUrl: element.url,
              logoUrl: sanityService.urlFor(element.logo).url(),
              mention: element.mention,
            };
          })
        )
      );
  }, []);

  return (
    <Content>
      <Headband text="Nos partenaires" />
      <div className="partenaires">
        {partners
          .filter((partner) => !partner.mention)
          .map((partner) => (
            <div key={partner.logoUrl} className="partenaire">
              <a
                href={partner.websiteUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={partner.logoUrl} alt="" />
              </a>
            </div>
          ))}
      </div>
      <>
        {partners.filter((partner) => partner.mention).length > 0 && (
          <Title title="les mentions spéciales" />
        )}
      </>
      <div className="partenaires">
        {partners
          .filter((partner) => partner.mention)
          .map((partner) => (
            <div key={partner.logoUrl} className="partenaire">
              <a
                href={partner.websiteUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={partner.logoUrl} alt="" />
              </a>
            </div>
          ))}
      </div>
    </Content>
  );
};

export default Partners;

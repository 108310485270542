import { useEffect, useState } from "react";
import Contact from "../../model/Contact";
import sanityService from "../../services/sanity-service";
import Content from "../content/Content";
import Headband from "../headband/Headband";
import Title from "../title/Title";
import ContactTile from "./ContactTile";
import "./Managers.css";



const Managers = () => {

    const [managers, setManagers] = useState<Contact[]>([]);

    useEffect(() => {
        sanityService.doQuery("*[_type == 'managers']  | order(lastname asc) {phone,firstname,lastname,position,email,photo}")
            .then(response => setManagers(response.map((element: any): Contact => {
                return {
                    firstname: element.firstname,
                    lastname: element.lastname,
                    photoUrl: sanityService.urlFor(element.photo).url(),
                    position: element.position,
                    phone: element.phone,
                    email: element.email,
                }
            })));

    }, []);

    return (
        <Content>
            <Headband text="l'encadrement" />
            <Title title="le bureau" />
            <div className="conctactsBureau">
                {managers.map(manager => <ContactTile key={manager.position} contact={manager} />)}
            </div>
        </Content>
    )
}

export default Managers;
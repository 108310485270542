import Contact from "../../model/Contact";
import "./ContactTile.css";

interface Properties {
    contact: Contact
}


const ContactTile = (properties: Properties) => {

    return (
        <div className="contact">
            <div className="contactPicture">
                <img id="contactImgPresident" src={properties.contact.photoUrl} loading="lazy"
                    alt={properties.contact.lastname} />
                <div className="overlay">
                    <div className="contactName">{properties.contact.firstname + " " + properties.contact.lastname}</div>
                </div>
            </div>
            <div className="contactDescription">
                <div className="contactStatus">{properties.contact.position}</div>
                <div className="contactInfos">
                    {properties.contact.phone && <>Tel : {properties.contact.phone}</>}
                </div>
                {properties.contact.email &&
                    <button className="button" onClick={() => properties.contact.email && navigator.clipboard.writeText(properties.contact.email)} >
                        <div id="mail" className="tooltip">{properties.contact.email}
                            <span id="mailTooltip" className="tooltiptext">Copier l'adresse ?</span>
                        </div>
                    </button>
                }
            </div>
        </div>
    );
}

export default ContactTile
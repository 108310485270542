import "./Headband.css"

interface Props {
    text: string
}

const Headband = ({ text }: Props) => {

    return (
        <div className="bigBandeau">
            <div className="column left"></div>
            <div className="column middle">
                {text.toUpperCase()}
            </div>
            <div className="column right"></div>
        </div>
    );

}

export default Headband;
import React from "react";
import "./Content.css"

interface Props {
    children: string | JSX.Element | JSX.Element[]
}

const Content = ({ children }: Props) => {
    return (<>
        <div id="content">
            {children}
        </div>
    </>);
}

export default Content;
import { DownloadRounded } from "@mui/icons-material";
import { Button, ToggleButton } from "@mui/material";
import { useEffect, useState } from "react";
import Countdown, { CountdownRendererFn } from "react-countdown";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Link } from "react-router-dom";
import sanityService from "../../services/sanity-service";
import Content from "../content/Content";
import Headband from "../headband/Headband";
import Title from "../title/Title";
import "./Inscriptions.css";

const Inscriptions = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [newInscription, setNewInscription] = useState<boolean>(false);
  const [renewInscription, setRenewInscription] = useState<boolean>(false);
  const [renewPDFURL, setRenewPDFURL] = useState<string>();
  const [renewZIPURL, setRenewZIP] = useState<string>();
  const [newPDFURL, setNewPDFURL] = useState<string>();
  const [newZIPURL, setNewZIPURL] = useState<string>();

  const today = new Date();
  const openingDay = new Date("2024-09-01");
  openingDay.setHours(0, 0, 0, 0);

  const areInscriptionsOpened = today >= openingDay;

  useEffect(() => {
    sanityService
      .doQuery(
        "*[_type == 'inscriptions'] {'renewPDF': renewNotice.asset->url,'renewZIP': renewZip.asset->url,'newPDF': newNotice.asset->url,'newZIP': newZip.asset->url}"
      )
      .then((resp) => {
        setRenewPDFURL(resp[0].renewPDF);
        setRenewZIP(resp[0].renewZIP);
        setNewPDFURL(resp[0].newPDF);
        setNewZIPURL(resp[0].newZIP);
      });
  }, []);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    if (value === "new") {
      setNewInscription(true);
      setRenewInscription(false);
    }
    if (value === "renew") {
      setNewInscription(false);
      setRenewInscription(true);
    }
  };

  const InscriptionDisplay = () => {
    if (
      (renewInscription || newInscription) &&
      renewPDFURL &&
      newPDFURL &&
      renewZIPURL &&
      newZIPURL
    ) {
      return (
        <>
          <Title title="Dossier d'inscription" />
          <div className="inscription_folder">
            <div className="text">
              Télécharge le dossier d'inscription ici :
            </div>
            <div>
              <Button
                target="_blank"
                href={renewInscription ? renewZIPURL : newZIPURL}
                size="large"
                color="primary"
                variant="contained"
                startIcon={<DownloadRounded />}
              >
                Dossier d'insciption
              </Button>
            </div>
          </div>
          <Title title="Notice d'inscription" />
          <div className="pdf">
            <Document file={renewInscription ? renewPDFURL : newPDFURL}>
              <Page pageNumber={1} renderTextLayer={false} scale={1.5} />
              <Page pageNumber={2} renderTextLayer={false} scale={1.5} />
              <Page pageNumber={3} renderTextLayer={false} scale={1.5} />
            </Document>
          </div>
        </>
      );
    } else return <></>;
  };

  const ClosedInscriptions = () => {
    return (
      <>
        <div className="closed-inscriptions">
          <p>
            Les inscriptions pour la saison 2024-2025 ouvriront le 1er septembre
            2024{" "}
          </p>
          <p>
            Si vous avez besoin de plus d'informations, n'hésitez pas contacter
            un membre du <Link to="/contacts">bureau</Link>
          </p>
        </div>
        <Countdown date={openingDay} renderer={countDownRenderer}></Countdown>
      </>
    );
  };

  const countDownRenderer: CountdownRendererFn = ({
    days,
    hours,
    minutes,
    seconds,
  }) => {
    return (
      <div className="count-down">
        <p>
          Ouverture dans {days} jour{days <= 1 ? "" : "s"} {hours}h {minutes}min{" "}
          {seconds}s
        </p>
      </div>
    );
  };

  const OnpenedInscriptions = () => {
    return (
      <>
        <div className="toggle">
          <ToggleButton
            color="secondary"
            size="large"
            value="new"
            onChange={handleChange}
            selected={newInscription}
          >
            Nouvelle inscription
          </ToggleButton>
          <ToggleButton
            color="secondary"
            size="large"
            value="renew"
            onChange={handleChange}
            selected={renewInscription}
          >
            Renouvellement de licence
          </ToggleButton>
        </div>
        <InscriptionDisplay />
      </>
    );
  };

  return (
    <Content>
      <Headband text="les inscriptions" />
      {areInscriptionsOpened ? <OnpenedInscriptions /> : <ClosedInscriptions />}
    </Content>
  );
};

export default Inscriptions;

import Banner from "../banner/Banner";
import Content from "../content/Content";
import News from "../news/News";
import Title from "../title/Title";
import "./LandingZone.css";

const LandingZone = () => {
    return (<>
        <Banner />
        <Content>
            <Title title="Les news du club" />
            <News />
        </Content>
    </>);

}

export default LandingZone;